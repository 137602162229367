import React from 'react'
// import PropTypes from 'prop-types'
import Typography from '../components/atoms/Typography';
import ErrorTemplate from '../components/templates/Error';
import { useTranslation } from '../config/i18n';


const NotFound = () => {
    const { t } = useTranslation('warnings');

    return (
        <ErrorTemplate title="Not found ">
             <Typography variant="h1"> 404</Typography>
             {/* <Typography variant="h3"> Opps, Sorry an error occured</Typography> */}
             <Typography variant="h3">{t('err.not_found')}</Typography>
        </ErrorTemplate>
    )
}

NotFound.propTypes = {

}

export default NotFound
